<template>
    <div class="info" id="watermark">
      <van-cell-group>
        <van-cell class="info-title">
          <template #title>
            <div class="blue-box"></div>
            <span class="custom-title">微型消防站信息</span>
          </template>
        </van-cell>
        <van-cell title="所在位置">
          <van-field v-model="submitData.position" placeholder="请输入" readonly/>
        </van-cell>
        <van-cell title="消防车车辆">
          <van-field v-model="submitData.fireEngines+'辆'" placeholder="请输入" readonly/>
        </van-cell>
      </van-cell-group>
      <van-cell-group class="info-second">
        <van-cell class="info-title">
          <template #title>
            <div class="blue-box"></div>
            <span class="custom-title">成员列表</span>
          </template>
        </van-cell>
        <div class="list-item" v-for="item in submitData.memberList">
          <van-field
              readonly
              v-model="item.post==2?'队长':'队员'"
              :label="item.name">
          <template #right-icon>
              <a :href="'tel:'+item.mobile" @click.stop=""><img   width="30" height="30" src="@/assets/img/phone.png" alt="" class="tel"/></a>
          </template>
          </van-field>
        </div>
      </van-cell-group>
      <van-row class="btns">
        <van-row gutter="10">
          <van-col :span="12">
            <van-button plain type="danger" size="large" round @click="deleteHandle"
                        style="background-color:transparent">删除
            </van-button>
          </van-col>
          <van-col :span="12">
            <van-button type="info" size="large" round @click="goEdit(submitData.id)">编辑</van-button>
          </van-col>
        </van-row>
      </van-row>
    </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'
import {formatterDateTimeWithOutSec} from "@/utils/utils"
import {getImageStream} from '@/utils/index'
import {getDictTree} from "@/utils/common";
import placeList from "../visit/placeList"
export default {
  data () {
    return {
      i: '',
      visible: true,
      edit: false,
      file:{},
      detailData: {},
      fieldNames: {
        text: 'label',
        value: 'value',
        children: 'children',
      },
      loading: false,
      placeListVisible: false,
      submitData: {
        id: '',
        position: '',
        fireEngines: '',
        memberList: [],
        orgId: ''
      },
      fileList: [],
      maxDate: new Date(),
    }
  },
  methods: {
    // 初始化
    init () {
      if (this.submitData.id) {
        this.edit = false
        this.getInfo()
      } else {
        this.edit =  true
      }
    },
    // 获取详情
    getInfo () {
      this.$http({
        url: this.$http.adornUrl('/wxapp/minifirestation/info/'+this.submitData.id),
        method: 'post',
        params: this.$http.adornParams({
        })
      }).then(({data})=> {
        if (data && data.code === 0) {
          this.submitData.position = data.appMiniFireStation.position
          this.submitData.fireEngines = data.appMiniFireStation.fireEngines
          this.submitData.memberList = data.appMiniFireStation.memberList
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    // 编辑
    goEdit (id) {
      this.$router.push({path: '/mini-add', query: {id: id}})
    },
    deleteHandle() {
      this.$dialog.confirm({
        title: '确定删除吗？'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/wxapp/minifirestation/delete'),
          method: 'post',
          params: this.$http.adornParams({
            id: parseInt(this.submitData.id)
          })
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.$toast.success({
              message: '删除成功',
              duration: 3000,
              onOpened: () => {
                setTimeout(() => {
                  this.$router.go(-1)
                }, 1500)
              }
            })
          } else {
            this.$toast.fail(data.msg)
          }
        })
      })
    },
    // 日期选项格式化
    formatDate (type, val) {
      if (type === 'year') {
        return val + '年'
      }
      if (type === 'month') {
        return val + '月'
      }
      if (type === 'day') {
        return val + '日'
      }
      if (type === 'hour') {
        return val + '时'
      }
      if (type === 'minute') {
        return val + '分'
      }
      return val
    },
  },
  created () {
    this.$watermark.set(this.$globalData.userInfo.userName, this.$globalData.userInfo.mobile)
    this.submitData.id = this.$route.query.id || ''
    document.querySelector('html').style.backgroundColor = '#fff'
    this.submitData.orgId = this.$orgId
    this.submitData.userId = this.$globalData.userInfo.userId
    this.init()
  },
  beforeDestroy () {
    document.querySelector('html').style.backgroundColor = ''
  }
}
</script>
<style lang="scss" scoped>

</style>
